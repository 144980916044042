import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import ScreensaverContext from './components/UI/ScreensaverContext';
import Screensaver from './components/UI/Screensaver';
import HeaderTotem from './components/UI/HeaderTotem';
import Content from './components/UI/Content';
import MenuTotem from './components/UI/MenuTotem';
import MenuTotemGenerale from './components/UI/MenuTotemGenerale';
import ContentGenerale from './components/UI/ContentGenerale';

function App() {
    const [showScreensaver, setShowScreensaver] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('i18nextLng') || 'en'); // Set default language if needed
    const [currentTotemId, setCurrentTotemId] = useState(null);
    const location = useLocation();

    // Fetch initial data for current totem page
    useEffect(() => {
        // Extract totem id from pathname
        const pathSegments = location.pathname.split('/');
        const totemId = pathSegments[1];

        // Fetch additional data based on totemId if needed
        if (totemId && totemId !== '') {
            setCurrentTotemId(totemId);
            // Example: Fetch additional data for totem page if needed
            // axios.get(`https://backend.fabbricadelfuturo.bbsitalia.com/${currentLanguage}/api/advertising-video/${totemId}`)
            //     .then(response => {
            //         // Handle response data
            //     })
            //     .catch(error => console.log(error));
        }
    }, [location.pathname, currentLanguage]);

    // Update language setting if it changes
    useEffect(() => {
        setCurrentLanguage(localStorage.getItem('i18nextLng') || 'en');
    }, []);

    // Handle screensaver display
    useEffect(() => {
        // Implement screensaver logic if needed
        // For example, setShowScreensaver(true) based on timeout or user interaction
        // setShowScreensaver(true);
    }, []);

    return (
        <ScreensaverContext.Provider value={{ showScreensaver, setShowScreensaver }}>
            <div className="bg-confAzzurro">
                <Routes>
                    <Route path="/:totemId" element={<TotemPage />} />
                </Routes>
            </div>
        </ScreensaverContext.Provider>
    );
}

// Separate component for handling totem page content
const TotemPage = () => {
    const { showScreensaver } = useContext(ScreensaverContext);
    const { pathname } = useLocation();
    const totemId = pathname.substring(1); // Remove leading '/' from pathname

    return (
        <section id="homepage" className="overflow-hidden h-screen">
            <Screensaver id="totem-generale" />
            <div className="h-15vh bg-fanoBlue">
                <HeaderTotem id={totemId} />
            </div>
            <div className="h-60vh bg-fanoBlue">
                <Content id={totemId} />
            </div>
            <div className="h-[25vh] bg-fanoBlue">
                {totemId === 'totem-generale' ? (
                    <MenuTotemGenerale id={totemId} />
                ) : (
                    <MenuTotem id={totemId} />
                )}
            </div>
        </section>
    );
};

export default App;
