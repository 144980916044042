import i18next from "i18next";
import 'flag-icon-css/css/flag-icons.min.css'
import {NavLink} from "react-router-dom";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { forwardRef } from "react";

const LanguageBox = forwardRef((props, ref) => {
    const { i18n } = useTranslation();
    const [isItalianSelected, setIsItalianSelected] = useState(
        i18n.language === "it"
    );
    const [isEnglishSelected, setIsEnglishSelected] = useState(
        i18n.language === "en"
    );
    const [showConfirmation, setShowConfirmation] = useState(false);

    function handleItalianChange(event) {
        const isChecked = event.target.checked;
        setIsItalianSelected(isChecked);
        if (isChecked) {
            setIsEnglishSelected(false);
            setShowConfirmation(true);
        }
    }

    function handleEnglishChange(event) {
        const isChecked = event.target.checked;
        setIsEnglishSelected(isChecked);
        if (isChecked) {
            setIsItalianSelected(false);
            setShowConfirmation(true);
        }
    }

    function handleConfirm() {
        i18n.changeLanguage(isItalianSelected ? "it" : "en");
        setShowConfirmation(false);
        // window.location.href = `/#/${props.id}`;
        window.location.reload();
    }

    function handleCancel(event) {
        setIsItalianSelected(i18n.language === "it");
        setIsEnglishSelected(i18n.language === "en");
        setShowConfirmation(false);
        event.stopPropagation();

    }

    const cancelButtonRef = useRef();


    const {t} = useTranslation()
    return (
                        <div className='px-1 py-1 z-99999 w-full' ref={ref}>

                            <div className="flex flex-wrap relative p-8 justify-center mb-5">
                                <label className="bg-white p-5 rounded-[6px] block items-center mr-10 h-22 relative flex items-center cursor-pointer">
                                            <span
                                                className={`flag-icon flag-icon-it mr-5 mt-1 text-3xl`}
                                            >
                                            </span>
                                    <input
                                        type="checkbox"
                                        className="text-gray-600 hidden"
                                        hidden={true}
                                        checked={isItalianSelected}
                                        onChange={handleItalianChange}
                                        style={{display: 'none!important'}}
                                    />
                                    <span className="ml-2 text-gray-700 text-fanoNero">Italiano</span>
                                </label>
                                <label className="bg-white p-5 rounded-[6px] block items-center h-22 relative flex items-center cursor-pointer">
                                            <span
                                                className={`flag-icon flag-icon-gb mr-5 mt-1 text-3xl`}
                                            >
                                            </span>
                                    <input
                                        hidden={true}
                                        type="checkbox"
                                        className="text-gray-600 hidden"
                                        checked={isEnglishSelected}
                                        onChange={handleEnglishChange}
                                        style={{display: 'none!important'}}
                                    />
                                    <span className="ml-2 text-gray-700 text-fanoNero">English</span>
                                </label>
                                {showConfirmation && ( // show confirmation popup only if showConfirmation is true
                                    <div className="fixed z-10 inset-0 overflow-y-auto">
                                        <div className="flex items-center justify-center min-h-screen">
                                            <div className={'bg-black opacity-60 absolute w-full min-h-screen z-0'}/>
                                            <div className="bg-white rounded-lg shadow-lg p-5 mx-2 relative z-10">
                                                <h2 className="text-lg font-medium mb-4">
                                                    {t('cambiolingua1')}
                                                </h2>
                                                <button className="mb-4" ref={cancelButtonRef} onClick={handleCancel}>
                                                    {t('cambiolingua2')}
                                                </button>
                                                <div className="">
                                                    <button
                                                        className="bg-confAzzurro text-white font-medium px-4 py-2 rounded-[6px] w-full mb-5"
                                                        onClick={handleConfirm}
                                                    >
                                                        {t('cambiolingua3')}
                                                    </button>
                                                    <button
                                                        className="text-black font-medium px-4 py-2 rounded-[6px] border border-confAzzurro w-full"
                                                        onClick={handleCancel}
                                                    >
                                                        {t('cambiolingua4')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
        );
});

export default LanguageBox
