import { useContext, useEffect, useRef, useState } from "react";
import axios from "../api";
import fanoLogo from '../../assets/fano.png';
import ScreensaverContext from './ScreensaverContext';
import mute from "../../assets/icons/mute.svg";
import unmute from "../../assets/icons/unmute.svg";
import home from "../../assets/icons/home.svg";
import {Link} from "react-router-dom";

const useAdvertisingVideos = (props) => {
    const [data, setData] = useState(null);
    const videoRefs = useRef([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isSingleVideo, setIsSingleVideo] = useState(false);
    const [isMuted, setIsMuted] = useState(false); // State per il controllo audio
    const [currentVideoTime, setCurrentVideoTime] = useState(0); // State per salvare la posizione del video corrente
    const currentLanguage = localStorage.getItem("i18nextLng");

    useEffect(() => {
        axios
            .get(`https://backend.fabbricadelfuturo.bbsitalia.com/${currentLanguage}/api/advertising-video/${props.id}`)
            .then((response) => {
                setData(response.data);
                setIsSingleVideo(response.data.length === 1);
                console.log("data received:", response.data);
            })
            .catch((error) => console.log(error));
    }, [props.id, currentLanguage]);

    useEffect(() => {
        const currentVideo = videoRefs.current[currentVideoIndex];
        if (currentVideo) {
            currentVideo.addEventListener("ended", handleVideoEnd);
            currentVideo.currentTime = currentVideoTime; // Ripristina la posizione del video corrente
            currentVideo.play();
            console.log("video started, currentVideoIndex:", currentVideoIndex);
        }

        return () => {
            if (currentVideo) {
                currentVideo.removeEventListener("ended", handleVideoEnd);
                currentVideo.pause();
                console.log("video paused, currentVideoIndex:", currentVideoIndex);
            }
        };
    }, [currentVideoIndex, videoRefs, data, currentVideoTime]);

    const handleVideoEnd = () => {
        if (!isSingleVideo) {
            setCurrentVideoIndex((currentVideoIndex + 1) % data.length);
        } else {
            const currentVideo = videoRefs.current[currentVideoIndex];
            currentVideo.currentTime = 0;
            currentVideo.play();
        }
    };

    const handlePageShow = () => {
        const currentVideo = videoRefs.current[currentVideoIndex];
        if (currentVideo) {
            currentVideo.load();
        }
    };

    useEffect(() => {
        window.addEventListener('pageshow', handlePageShow);
        return () => {
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, [currentVideoIndex, videoRefs]);

    const { showScreensaver } = useContext(ScreensaverContext);

    useEffect(() => {
        videoRefs.current.forEach((video) => {
            if (video) {
                video.volume = showScreensaver ? 0 : 1;
            }
        });
    }, [showScreensaver, videoRefs]);

    useEffect(() => {
        const currentVideo = videoRefs.current[currentVideoIndex];
        if (!showScreensaver) {
            if (currentVideo) {
                currentVideo.addEventListener("ended", handleVideoEnd);
                currentVideo.currentTime = currentVideoTime; // Ripristina la posizione del video corrente
                currentVideo.play();
                console.log("video started, currentVideoIndex:", currentVideoIndex);
            }
        } else {
            if (currentVideo) {
                currentVideo.removeEventListener("ended", handleVideoEnd);
                currentVideo.pause();
                currentVideo.currentTime = 0;
                currentVideo.load();
                console.log("video paused, currentVideoIndex:", currentVideoIndex);
            }
        }

        return () => {
            if (currentVideo) {
                currentVideo.removeEventListener("ended", handleVideoEnd);
                currentVideo.pause();
                currentVideo.currentTime = 0;
                currentVideo.load();
                console.log("video cleanup, currentVideoIndex:", currentVideoIndex);
            }
        };
    }, [currentVideoIndex, videoRefs, data, showScreensaver, currentVideoTime]);

    return { data, videoRefs, currentVideoIndex, isMuted, setIsMuted, setCurrentVideoTime };
};

const Content = (props) => {
    const currentLanguage = localStorage.getItem("i18nextLng");

    const { data, videoRefs, currentVideoIndex, isMuted, setIsMuted, setCurrentVideoTime } = useAdvertisingVideos(props);
    const [testo, setTesto] = useState(null);

    useEffect(() => {
        axios
            .get(`https://backend.fabbricadelfuturo.bbsitalia.com/${currentLanguage}/scritta/${props.id}`)
            .then((response) => {
                setTesto(response.data[0].testo);
            })
            .catch((error) => console.log(error));
    }, [props.id, currentLanguage]);

    const handleMuteToggle = () => {
        setIsMuted(!isMuted);
        videoRefs.current.forEach((video) => {
            if (video) {
                video.muted = !isMuted;
            }
        });
    };

    const handleVideoClick = () => {
        const currentVideo = videoRefs.current[currentVideoIndex];
        if (currentVideo) {
            setCurrentVideoTime(currentVideo.currentTime); // Salva la posizione corrente del video
        }
    };

    return (
        <div className={"bg-fanoBlue flex flex-wrap h-[60vh] relative overflow-hidden"}>
            {/*<img className={"w-[150px] h-auto m-auto relative z-10"} src={fanoLogo} alt="Fano Logo" />*/}
            {props.id !== 'totem-generale' &&
            <span className={'text-white text-5xl font-bold py-8 px-20'}>{testo}</span>
            }
            {data && data.map((item, index) => (
                <video
                    key={index}
                    ref={(element) => (videoRefs.current[index] = element)}
                    src={`https://backend.fabbricadelfuturo.bbsitalia.com${item.video}?timestamp=${Date.now()}`}
                    autoPlay={index === 0}
                    className={"w-full h-full object-cover relative top-0 left-0 z-auto mt-5"}
                    style={{display: index === currentVideoIndex ? "block" : "none"}}
                    onClick={handleVideoClick} // Aggiungi l'evento di click al video
                />
            ))}
            <button
                onClick={handleMuteToggle}
                className="absolute bottom-2 right-6 z-10 rounded-md text-white"
            >
                {isMuted && <img src={mute}/>}
                {!isMuted && <img src={unmute}/>}
            </button>
            {props.id !== 'totem-generale' &&
                <Link
                    to={'/totem-generale'}
                    className="absolute bottom-2 left-6 z-10 rounded-[15px] text-white bg-[#1d59a3] w-[88px] h-[88px] flex justify-center items-center"
                >
                    <img className={'w-16'} src={home}/>
                </Link>
            }

        </div>
    );
};

export default Content;
