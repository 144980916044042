import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

import './Menu.css'
import axios from "../api";

import arrowRight from '../../assets/icons/arrow-right.svg'
const Menu = (props) => {

    const currentLanguage = localStorage.getItem("i18nextLng");
    // alert(currentLanguage)


    const [data, setData] = useState(null);
    const [extra, setExtra] = useState(null);

    useEffect(() => {
        axios.get(`https://backend.fabbricadelfuturo.bbsitalia.com/${currentLanguage}/api/menu/${props.id}`)
            .then(response => setData(response.data))
            .catch(error => console.log(error));
    }, [props.id, currentLanguage]);

    // useEffect(() => {
    //     axios.get(`https://backend.fabbricadelfuturo.bbsitalia.com/${currentLanguage}/api/menu_extra_totem`)
    //         .then(response => setExtra(response.data))
    //         .catch(error => console.log(error));
    // }, []);


    return(
        <div className={'h-full flex flex-wrap items-center content-center bg-fanoBlue z-20 relative'}>

                    <ul className={`z-10 relative items-center align-middle content-center flex flex-wrap duration-300 p-5`}>
                        {data && data?.map((item, index) => {
                            return (
                                <li className={`flex w-1/3 h-32 pb-3 px-2 `}
                                    key={index}
                                >
                                        <a className={'bg-white shadow-fanoShadow w-full h-full flex justify-between items-center rounded-2xl p-4'} href={item.url}>
                                            <img src={`https://backend.fabbricadelfuturo.bbsitalia.com${item.icona}`} className={'w-auto max-w-[200px] max-h-[80px] ml-5'} alt={item.titolo}/>
                                            <img src={arrowRight} className={'w-[35px] ml-5'}/>
                                        </a>

                                </li>
                            )
                        })}
                    </ul>
        </div>

    )
}

export default Menu;
