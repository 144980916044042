import { useEffect, useRef, useState } from "react";
import axios from "../api";
import fanoLogo from '../../assets/fano.png';
import logo1 from '../../assets/fabbricadelfuturo.jpg'
import logo2 from '../../assets/confindustriabrescia.png'
import logo3 from '../../assets/capitaleculturabresciabergamo.jpeg'
import bandaloghiff from '../../assets/bandaloghiff.png'


const HeaderTotem = () => {



    return (
        <div className={"h-[15vh] m-0 relative px-20"}>
            <div className={'rounded-bl-[10px] rounded-br-[10px] bg-white h-[100%] w-full flex flex-wrap content-center items-center p-10'}>
                {/*<img className={"w-1/3 h-auto m-auto p-5 relative z-10"} src={logo1} alt="Fabbrica del futuro Logo" />*/}
                {/*<img className={"w-1/3 h-auto m-auto p-5 relative z-10"} src={logo2} alt="Confindustria brescia Logo" />*/}
                {/*<img className={"w-1/3 h-auto m-auto p-5 relative z-10"} src={logo3} alt="Siamo capitale italiana della cultura 2023 bergamo brescia Logo" />*/}
                <img src={bandaloghiff} alt="Banda loghiff Logo" />
            </div>
        </div>
    );
};

export default HeaderTotem;
