import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HashRouter} from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';



i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        supportedLngs: ['it', 'en'],
        resources: {
            en: {
                translation: {
                    "cambiolingua1": "Changing the language will restart the app",
                    "cambiolingua2": "Are you sure you want to change the language?",
                    "cambiolingua3": "Confirm",
                    "cambiolingua4": "Cancel",
                }

            },
            it: {
                translation: {
                    "cambiolingua1": "Cambiando la lingua l'app verrá riavviata",
                    "cambiolingua2": "Sei sicuro di voler cambiare la lingua?",
                    "cambiolingua3": "Conferma",
                    "cambiolingua4": "Annulla",

                }
            }
        },
        fallbackLng: "it",
        detection: {
            order: ['localStorage', 'path', 'cookie', 'htmlTag', 'path', 'subdomain'],
            caches:['localStorage', 'cookie'],
        },
    });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter>
        <App/>
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
