import React, {useState, useEffect, useCallback, useRef, useContext} from 'react';
// import fanoLogo from "";
import axios from "axios";
import VideoPlayer from "./VideoPlayer";
import LanguageBox from "./LanguageBox";
import ScreensaverContext from './ScreensaverContext';
import {useNavigate} from "react-router-dom";
function Screensaver(props) {
    const { showScreensaver, setShowScreensaver } = useContext(ScreensaverContext);
    const timeoutRef = useRef();
    const [videoData, setVideoData] = useState(null);

    const resetTimeout = useCallback(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setShowScreensaver(true), 180000);
    }, []);

    useEffect(() => {
        let lastInteractionTime = Date.now();

        const handleInteraction = (event) => {
            if (languageBoxRef.current && languageBoxRef.current.contains(event.target)) {
                return;
            }

            if (showScreensaver) {
                lastInteractionTime = Date.now();
                setShowScreensaver(false);
            } else {
                lastInteractionTime = Date.now();
                resetTimeout();
            }
        };

        document.addEventListener('click', handleInteraction);
        document.addEventListener('touchstart', handleInteraction);

        resetTimeout();

        return () => {
            document.removeEventListener('click', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
            clearTimeout(timeoutRef.current);
        };
    }, [resetTimeout, showScreensaver]);

    const navigate = useNavigate()
    function handleScreensaverClick() {
        setShowScreensaver(false);
        navigate('/totem-generale')
    }

    useEffect(() => {
        if (showScreensaver) {
            clearTimeout(timeoutRef.current);
        }
    }, [showScreensaver]);

    const currentLanguage = localStorage.getItem("i18nextLng");

    useEffect(() => {
        axios
            .get(`https://backend.fabbricadelfuturo.bbsitalia.com/${currentLanguage}/api/advertising-video/screensaver/${props.id}`)
            .then((response) => {
                setVideoData(response.data);
                console.log("data received:", response.data);
            })
            .catch((error) => console.log(error));
    }, [props.id, currentLanguage]);

    const handleVideoEnd = useCallback(() => {
        resetTimeout();
    }, [resetTimeout]);

    const languageBoxRef = useRef();


    return (
        <>

            {showScreensaver && (
                <>
                    <div className={'absolute w-full text-center bottom-40 overflow-hidden z-[999999]'}>
                        <LanguageBox ref={languageBoxRef} id={props.id} />
                    </div>
                    <div className='overflow-hidden absolute top-0 left-0 w-full z-[9999] h-screen' onClick={handleScreensaverClick} onTouchStart={handleScreensaverClick}>
                        <div className={'absolute w-full text-center top-20 overflow-hidden'}>
                            {/*<img className={"w-[350px] h-auto m-auto relative z-10"} src={fanoLogo} alt="Fano Logo" />*/}
                        </div>
                        <div className={"w-full h-full object-cover absolute top-0 left-0 z-auto"}>
                            <VideoPlayer videoData={videoData} onVideoEnd={handleVideoEnd} />
                        </div>
                        <div className={'absolute w-full text-center bottom-20 overflow-hidden flex'}>
                            <div className={'text-center w-[60%] bottom-20 overflow-hidden bg-confAzzurro rounded-[10px] m-auto block p-4'}>
                                <p className={"text-white text-4xl font-bold"}>
                                    {currentLanguage === 'it'
                                        ? `Clicca lo schermo\n per utilizzare l'infopoint interattivo`
                                        : `Click the screen to use the interactive infopoint`}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Screensaver;
